import React from "react";

function Blogs() {
  return (
    <div id="blogs-page" style={{ background: "white", height: "20rem" }}>
      <p className="about-header">Coming Soon. I am working on it.</p>
    </div>
  );
}

export default Blogs;
